import { FlashSaleType } from '@/constants'
import { useMemo } from 'react'
export interface ProgressBarProps {
  flashSale: {
    quantity: number
    soldQuantity: number
    endTime: number
  }
  type?: string
  fsType?: FlashSaleType
}

const ProgressBar = ({ flashSale, type, fsType }: ProgressBarProps) => {
  const leftItems = useMemo(() => {
    if (fsType === FlashSaleType.PROMOTION) {
      return 99
    }
    if (flashSale) {
      return (
        ((Number(flashSale.quantity) - Number(flashSale.soldQuantity)) /
          Number(flashSale.quantity)) *
        100
      )
    }
    return 0
  }, [flashSale])

  const renderProgressSeller = useMemo(() => {
    if (leftItems <= 0 && flashSale.soldQuantity > 0) {
      return (
        <div className='totalCount'>
          <i className='outStockIcon'></i>
          <p
            className={`available absolute left-[50%] top-[50%] w-full translate-x-[-50%] translate-y-[-50%]`}
          >
            Đã bán hết
          </p>
        </div>
      )
    } else if (leftItems < 90 && leftItems > 0) {
      return (
        <div className='totalCount'>
          <p
            className={`available absolute left-[50%] top-[50%] w-full translate-x-[-50%] translate-y-[-50%]`}
          >{` Còn ${flashSale.quantity - flashSale.soldQuantity}/${
            flashSale.quantity
          } suất`}</p>
        </div>
      )
    } else if (leftItems >= 90) {
      return (
        <div className='totalCount'>
          <p
            className={`available absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-center`}
          >{`Vừa mở bán`}</p>
        </div>
      )
    }
  }, [flashSale])
  return (
    <div className={`${type == 'product-detail' ? ' mt-2' : 'pt-1'}`}>
      <div
        className={`${
          leftItems <= 0 && flashSale.soldQuantity > 0
            ? 'bg-primary-second'
            : 'bg-dark-900'
        } ${
          type == 'product-detail'
            ? 'mt-[5px] rounded-full !bg-gray-100'
            : 'rounded-lg'
        } relative w-full overflow-hidden`}
      >
        <div
          className={`${leftItems <= 0 ? 'bg-gray-100' : 'bg-primary-second'} ${
            type == 'product-detail'
              ? 'mt-0 h-[28px] justify-center rounded-full bg-flashSale text-sm font-semibold text-dark-900'
              : 'mt-0 h-[28px] rounded-lg text-xs font-medium leading-none text-white'
          } flex items-center text-center`}
          style={{
            width: leftItems >= 90 ? '100%' : leftItems + '%',
          }}
        >
          {renderProgressSeller}
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
