import type { Product, PromotionProductCarousel } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Link from 'next/link'
import ImageOptimize from '../ImageOptimize'
import ProductCard from '../ProductCard'
interface ProductGridProps {
  data: PromotionProductCarousel
  index: number
}
const ProductGrid = ({ data }: ProductGridProps) => {
  const bannerTop = data?.customization?.sliders.filter(
    e => e?.position === 'top'
  )
  switch (data?.layout) {
    case 2:
      return (
        <section
          className='productGrid mb-5 rounded-sm pb-3 pt-2'
          // style={customStyle}
        >
          <div className='productGrid--header relative mb-2 '>
            {!data?.customization?.backgroundImage ? (
              <>
                <h2
                  className='relative w-full text-titleSection font-medium text-dark-400'
                  style={{
                    color: data?.customization?.titleColor,
                  }}
                >
                  {getTranslate(data?.name)}
                </h2>
                {data?.description &&
                  getTranslate(data?.description) !== '' && (
                    <p
                      className='sortDescription text-mainBody text-dark-600'
                      style={{
                        color: data?.customization?.descriptionColor,
                      }}
                    >
                      {getTranslate(data?.description)}
                    </p>
                  )}
              </>
            ) : (
              ''
            )}
            <Link
              href={data?.customization?.viewMoreLink || '/'}
              className='btnMore absolute right-0 top-1 flex items-center justify-center gap-1 text-base text-primary-base'
            >
              Xem tất cả
            </Link>
          </div>
          <div className='grid grid-cols-5 gap-3'>
            <div className='col-span-2'>
              {bannerTop && bannerTop.length > 0 && (
                <div className='bannerTop relative h-full w-full'>
                  {bannerTop?.map((item, idx) => (
                    <Link
                      href={item?.link || ''}
                      key={data._id + '_top_' + idx}
                      className='block h-full w-full overflow-hidden rounded-lg'
                    >
                      <ImageOptimize
                        src={item?.image || ''}
                        alt={item?.name || ''}
                        className='h-full w-full object-cover'
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <div className='col-span-3'>
              {data && (
                <div className='productGrid--content grid grid-cols-3 gap-3'>
                  {data &&
                    data?.products?.slice(0, 6).map((product: Product) => (
                      <div className='w-full' key={product._id}>
                        <ProductCard
                          product={product}
                          layout={5}
                          // type={ProductCardType.LIST_HOT}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </section>
      )
    case 3:
      return (
        <section
          className='productGrid mb-5 rounded-sm pb-3 pt-2'
          // style={customStyle}
        >
          <div className='productGrid--header relative mb-2 '>
            {!data?.customization?.backgroundImage ? (
              <>
                <h2
                  className='relative w-full text-titleSection font-medium text-dark-400'
                  style={{
                    color: data?.customization?.titleColor,
                  }}
                >
                  {getTranslate(data?.name)}
                </h2>
                {data?.description &&
                  getTranslate(data?.description) !== '' && (
                    <p
                      className='sortDescription text-mainBody text-dark-600'
                      style={{
                        color: data?.customization?.descriptionColor,
                      }}
                    >
                      {getTranslate(data?.description)}
                    </p>
                  )}
              </>
            ) : (
              ''
            )}
            <Link
              href={data?.customization?.viewMoreLink || '/'}
              className='btnMore absolute right-0 top-1 flex items-center justify-center gap-1 text-base text-primary-base'
            >
              Xem tất cả
            </Link>
          </div>

          {data && (
            <div className='productGrid--content grid grid-cols-5 gap-3'>
              <div className='col-span-2'>
                {bannerTop && bannerTop.length > 0 && (
                  <div className='bannerTop relative h-full w-full'>
                    {bannerTop?.map((item, idx) => (
                      <Link
                        href={item?.link || ''}
                        key={data._id + '_top_' + idx}
                        className='block h-full w-full overflow-hidden rounded-lg'
                      >
                        <ImageOptimize
                          src={item?.image || ''}
                          alt={item?.name || ''}
                          className='h-full w-full object-cover'
                        />
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              {data &&
                data?.products?.slice(0, 8).map((product: Product) => (
                  <div className='w-full' key={product._id}>
                    <ProductCard
                      product={product}
                      layout={5}
                      // type={ProductCardType.LIST_HOT}
                    />
                  </div>
                ))}
            </div>
          )}
        </section>
      )

    default:
      return (
        <section
          className='productGrid mb-5 rounded-sm pb-3 pt-2'
          // style={customStyle}
        >
          <div className='productGrid--header relative mb-2 '>
            {!data?.customization?.backgroundImage ? (
              <>
                <h2
                  className='relative w-full text-titleSection font-medium text-dark-400'
                  style={{
                    color: data?.customization?.titleColor,
                  }}
                >
                  {getTranslate(data?.name)}
                </h2>
                {data?.description &&
                  getTranslate(data?.description) !== '' && (
                    <p
                      className='sortDescription text-mainBody text-dark-600'
                      style={{
                        color: data?.customization?.descriptionColor,
                      }}
                    >
                      {getTranslate(data?.description)}
                    </p>
                  )}
              </>
            ) : (
              ''
            )}
            <Link
              href={data?.customization?.viewMoreLink || '/'}
              className='btnMore absolute right-0 top-1 flex items-center justify-center gap-1 text-base text-primary-base'
            >
              Xem tất cả
            </Link>
          </div>
          {bannerTop && bannerTop.length > 0 && (
            <div className='bannerTop relative my-3 w-full'>
              {bannerTop?.map((item, idx) => (
                <Link
                  href={item?.link || ''}
                  key={data._id + '_top_' + idx}
                  className='block w-full overflow-hidden rounded-lg'
                >
                  <ImageOptimize
                    src={item?.image || ''}
                    alt={item?.name || ''}
                    className='w-full'
                  />
                </Link>
              ))}
            </div>
          )}
          {data && (
            <div className='productGrid--content grid grid-cols-5 gap-3'>
              {data &&
                data?.products?.map((product: Product) => (
                  <div className='w-full' key={product._id}>
                    <ProductCard
                      product={product}
                      layout={5}
                      // type={ProductCardType.LIST_HOT}
                    />
                  </div>
                ))}
            </div>
          )}
        </section>
      )
  }
}
export default ProductGrid
