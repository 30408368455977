import type { Product } from '@/types'
import { type FlashSale as FlashSaleType } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import ImageOptimize from '../ImageOptimize'
import Countdown from '../ProductCard/Countdown'
import ProductCardFS from '../ProductCard/ProductCardFS'
interface Props {
  flashSale: FlashSaleType | null
}

const FlashSaleV1 = ({ flashSale }: Props) => {
  return (
    <section className='my-5 rounded-xl bg-white'>
      {flashSale && flashSale?.products?.length > 0 ? (
        <div className={`${flashSale ? ' px-2 pb-3 pt-4' : ''}`}>
          <div className='flash-sale--header relative'>
            <ImageOptimize
              src={flashSale?.customization.blockBackgroundImage}
              alt={getTranslate(flashSale.name) || ''}
              width={1920}
              height={500}
            />
            <div className='absolute left-5 top-[186px] w-full'>
              <Countdown
                productId={''}
                endTime={Number(flashSale?.endTime)}
                type={'flashSaleBlock'}
                className={''}
              />
            </div>
          </div>
          <div className='flash-sale--content relative'>
            <div className='gridFs grid grid-cols-3 gap-3'>
              {flashSale?.products.map((product: Product, idx: number) => (
                <ProductCardFS
                  key={product._id + idx + 'fs'}
                  product={product}
                  type='flashSale'
                  index={idx}
                  className='grid__item'
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </section>
  )
}
export default FlashSaleV1
