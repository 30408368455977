import type { BannerItem as HomeSliderType } from '@/types'
import Link from 'next/link'
import { useRef, type FC } from 'react'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'

interface Props {
  sliders: Array<HomeSliderType>
}

export const HomeSliderV1: FC<Props> = ({ sliders }: Props) => {
  const sliderSettings: Settings = {
    autoplay: true,
    dots: true,
    autoplaySpeed: 7000,
    speed: 800,
    infinite: true,
    pauseOnHover: true,
    slidesToShow: 1,
  }
  const ref = useRef<Slider>(null)
  const chunkArray = (arr: Array<HomeSliderType>, chunkSize: number) => {
    if (!arr) {
      return []
    }
    const result = []
    for (let i = 0; i < arr?.length; i += chunkSize) {
      const chunk = arr?.slice(i, i + chunkSize)
      result.push({ idx: result?.length + 1, items: chunk })
    }
    return result
  }

  const newArray: { idx: number; items: HomeSliderType[] }[] = chunkArray(
    sliders,
    4
  )

  return (
    <section className={`${!sliders && 'hidden'} container mx-auto mb-4`}>
      {sliders ? (
        <Slider ref={ref} {...sliderSettings} className=' w-full'>
          {newArray &&
            newArray.map(
              (
                slider: { idx: number; items: HomeSliderType[] },
                index: number
              ) => (
                <div className='!grid grid-cols-3 gap-3' key={index}>
                  <div className='col-span-2'>
                    <div className='grid grid-cols-1 gap-3'>
                      <div className='w-full'>
                        {slider &&
                          slider?.items?.slice(0, 1).map((e, idx) => (
                            <div className='w-full' key={idx}>
                              <Link
                                href={e.link || '/'}
                                className='block h-full w-full overflow-hidden rounded outline-none'
                              >
                                <ImageOptimize
                                  width={869}
                                  height={322}
                                  src={e.image}
                                  alt={e.title}
                                  className='h-full w-full object-cover'
                                />
                              </Link>
                            </div>
                          ))}
                      </div>
                      <div className='grid w-full grid-cols-2 gap-3'>
                        {slider &&
                          slider?.items?.slice(1, 3).map((e, idx) => (
                            <div className='w-full' key={idx}>
                              <Link
                                href={e.link || '/'}
                                className='block w-full overflow-hidden rounded outline-none'
                              >
                                <ImageOptimize
                                  width={869}
                                  height={322}
                                  src={e.image}
                                  alt={e.title}
                                  className='h-auto w-full object-cover'
                                />
                              </Link>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className={`col-span-1`}>
                    <div className={`grid w-full grid-cols-1 gap-3`}>
                      {slider &&
                        slider?.items?.slice(3, 4).map((e, idx) => (
                          <Link
                            href={e.link || '/'}
                            className='block h-full w-full overflow-hidden rounded outline-none'
                            key={idx}
                          >
                            <ImageOptimize
                              width={196}
                              height={92}
                              src={e.image}
                              alt={e.title}
                              className='h-full w-full object-cover'
                            />
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
              )
            )}
        </Slider>
      ) : (
        ''
      )}
      {/* {sliders ? (
        <div className='mt-3 flex items-center justify-center gap-x-1'>
          <div
            className='pointer-events-auto w-[40px] cursor-pointer rounded bg-dark-100 p-[11px]'
            onClick={() => {
              ref.current?.slickPrev()
            }}
          >
            <img src='/assets/icons/arrow/ArrowPrev.svg' alt='Prev' />
          </div>
          <span className='h-[40px] w-[58px] justify-center text-center leading-[40px]'>
            <span className='text-base text-dark-900'>{activeSlide}</span>
            <span className='text-base text-dark'>/{totalNum}</span>
          </span>
          <div
            className='pointer-events-auto w-[40px] cursor-pointer rounded bg-dark-100 p-[11px]'
            onClick={() => {
              ref.current?.slickNext()
            }}
          >
            <img
              src='/assets/icons/arrow/ArrowPrev.svg'
              alt='Next'
              className='rotate-180'
            />
          </div>
          <div
            onClick={handlePlayStopClick}
            className='pointer-events-auto flex w-[40px] cursor-pointer items-center justify-center rounded bg-dark-100 p-[11px]'
          >
            {isAutoPlaying ? (
              <img
                src='/assets/icons/arrow/pause.svg'
                alt='Pause'
                className='rotate-180'
              />
            ) : (
              <img src='/assets/icons/arrow/play.svg' alt='Play' />
            )}
          </div>
        </div>
      ) : (
        ''
      )} */}
    </section>
  )
}
