import { Banner } from '@/components/Banner'
import FlashSaleV1 from '@/components/FlashSale/FlashSaleV1'
import { HomeSliderV1 } from '@/components/HomeSlider/HomeSliderV1'
import ProductGrid from '@/components/ProductGrid'
import QuickMenuHome from '@/components/QuickMenu/QuickMenuHome'
import { BannerType } from '@/constants'
import { useFlashSale } from '@/containers/FlashSaleContainer'
import { Meta } from '@/layouts/Meta'
import { Main } from '@/templates/Main'
import type { Keywords, Menu, SystemConfig } from '@/types'
import {
  type BannerList,
  type Promotion,
  type PromotionProductCarousel,
  type Setting,
} from '@/types'
import { getTranslate, setContext } from '@/utils/api-interceptors'
import {
  getBannerSSR,
  getBottomMenu,
  getConfigSSR,
  getFlashSale,
  getHomeMenu,
  getHomepageProductCarousel,
  getKeyword,
  getMenus,
  getProductTrending,
  getQuickMenu,
  getSaleRegion,
  getYourPromotions,
} from '@/utils/endpoint'
import { getCookies } from 'cookies-next'
import { type GetServerSideProps } from 'next'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
const BlogList = dynamic(() => import('@/components/BlogList'), {
  ssr: false,
})
const HomeBanner = dynamic(() => import('@/components/HomeBanner'), {
  ssr: false,
})

type HomeProps = {
  setting?: Setting
  promotion: Promotion
  banner: BannerList[]
  yourPromotions: Promotion[]
  productCarousel: PromotionProductCarousel[]
  keywords: Keywords
  systemConfig: SystemConfig
  quickMenu: Menu[]
  homeMenu: Menu[]
}
const Home = ({
  banner,
  productCarousel,
  homeMenu,
  systemConfig,
}: HomeProps) => {
  const t = useTranslations('Homepage')
  const { flashSale } = useFlashSale()
  const router = useRouter()
  const middleBanner = useMemo(() => {
    return banner.filter(banner => {
      return banner.type === BannerType.HOME && banner.status === 1
    })
  }, [banner])
  const mainSlider = useMemo(() => {
    return banner.filter(banner => {
      return banner.type === BannerType.MAIN && banner.status === 1
    })
  }, [banner])
  const footerBanner = banner.filter(banner => {
    return banner.type === BannerType.HOME_FOOTER && banner.status === 1
  })
  return (
    <Main
      meta={
        <Meta
          title={getTranslate(systemConfig?.seoMetaTitle)}
          description={getTranslate(systemConfig?.seoMetaDescription)}
          imageUrl={systemConfig?.seoImageDefault || ''}
          imageAlt={systemConfig?.websiteBrandName || ''}
          canonical={
            process.env.NEXT_PUBLIC_URL_WEBSITE + router.asPath ||
            process.env.NEXT_PUBLIC_URL_WEBSITE
          }
          noFollow={false}
          noIndex={false}
          mobileAlternate={{
            media: 'only screen and (max-width:991px))',
            href:
              process.env.NEXT_PUBLIC_URL_WEBSITE_MOBILE + router.asPath ||
              process.env.NEXT_PUBLIC_URL_WEBSITE_MOBILE ||
              '',
          }}
        />
      }
      page='homepage'
      bgColor='#f6f4f5'
    >
      <div className='max-w-content w-full overflow-hidden'>
        <HomeSliderV1 sliders={mainSlider[0]?.items} />
      </div>
      <div className='container mx-auto w-full pt-8'>
        <HomeBanner
          banner={middleBanner[0]?.items}
          layout={middleBanner[0]?.layout}
          data={middleBanner[0]}
        />
        <HomeBanner
          banner={middleBanner[1]?.items}
          layout={middleBanner[1]?.layout}
          data={middleBanner[1]}
        />
        {systemConfig && systemConfig.flashSaleHomepage == 'true' ? (
          <FlashSaleV1 flashSale={flashSale} />
        ) : (
          ''
        )}
        <QuickMenuHome QuickMenuData={homeMenu} />
        {productCarousel &&
          productCarousel
            ?.sort((a, b) => a.order - b.order)
            ?.map((e, i) => {
              return <ProductGrid data={e} index={i} key={i} />
            })}
        <BlogList title={t('blogListTitle')} subTitle={t('blogListSubTitle')} />
        <Banner dataBanner={footerBanner[0]?.items} className={''} />
      </div>
    </Main>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  locale,
}) => {
  const { token, language, messages } = await setContext(
    getCookies({ req, res }),
    {
      language: locale,
    }
  )
  const [
    promotion,
    flashSale,
    yourPromotions,
    menus,
    quickMenu,
    homeMenu,
    banner,
    bottomMenu,
    systemConfig,
    productCarousel,
    keywords,
    regions,
  ] = await Promise.all([
    getProductTrending(),
    getFlashSale(),
    getYourPromotions(),
    getMenus(),
    getQuickMenu(),
    getHomeMenu(),
    getBannerSSR(),
    getBottomMenu(),
    getConfigSSR(),
    getHomepageProductCarousel(),
    getKeyword(),
    getSaleRegion(),
  ])
  return {
    props: {
      yourPromotions,
      messages,
      promotion,
      flashSale,
      menus,
      quickMenu,
      homeMenu,
      bottomMenu,
      banner,
      language,
      systemConfig,
      productCarousel,
      keywords,
      regions,
      token: token || null,
    },
  }
}
