import type { BannerItem as BlockBanner } from '@/types'
import Link from 'next/link'
import { type FC } from 'react'
import ImageOptimize from '../ImageOptimize'

interface Props {
  dataBanner: BlockBanner[]
  className: string
}

export const Banner: FC<Props> = ({ dataBanner, className }: Props) => {
  return (
    <>
      {dataBanner && (
        <div className={`w-full overflow-hidden rounded-lg ${className}`}>
          <Link href={dataBanner[0]?.link || '/'}>
            <ImageOptimize
              width={1312}
              height={480}
              src={dataBanner[0]?.image}
              alt={dataBanner[0]?.title}
            />
          </Link>
        </div>
      )}
    </>
  )
}
